import history from "@history.js";
import jwtAuthService from "../../services/jwtAuthService";
import { Api } from "../api";

export const SET_USER_DATA = "USER_SET_DATA";
export const SET_USER_ROLE = "USER_SET_ROLE";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const GET_ROLE = "GET_ROLE";
export const GET_LIST_USER = "GET_LIST_USER";
export const GET_USER_DETAIL = "GET_USER_DETAIL";

export const SET_ADMINISTRATOR_ROLE = "SET_ADMINISTRATOR_ROLE";
export const SET_ADMIN_ROLE = "SET_ADMIN_ROLE";
export const SET_SALES_ROLE = "SET_SALES_ROLE";
export const SET_INVESTOR_ROLE = "SET_INVESTOR_ROLE";
export const SET_OPMAN_ROLE = "SET_OPMAN_ROLE";
export const UNRECOGNIZED_ROLE = "UNRECOGNIZED_ROLE";

export function setUserData(user) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    });
  };
}

export function setUserRole(data) {
  console.log('roleData:', data)
  return (dispatch) => {
    dispatch({
      type: SET_USER_ROLE,
      data: data,
    });
  };
}

export function logoutUser() {
  return (dispatch) => {
    jwtAuthService.logout();

    history.push({
      pathname: "/session/signin",
    });

    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}

export const getListRole = (page, size) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`auth/role?page=${page}&size=${size}`);
      let data = resp.data.data ? resp.data.data : [];

      dispatch({
        type: GET_ROLE,
        data,
      });
      // return data
    } catch (err) {
      console.log(err);
      throw err?.response?.data;
    }
  };
};

export const getListUser = ({page, size, orgId = '', clubId = '', search = ''}) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`user?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&search=${search}`);
      let data = resp.data.data ? resp.data.data : [];

      dispatch({
        type: GET_LIST_USER,
        data,
      });
      // return data
    } catch (err) {
      console.log(err);
      throw err?.response?.data;
    }
  };
};

export const getUserDetail = (userId) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`user/${userId}`);
      let data = resp.data.data ? resp.data.data : [];

      let type;

      switch (data[0]?.role?.name?.toLowerCase()) {
        case "administrator":
          type = SET_ADMINISTRATOR_ROLE
          break;
        case "admin":
          type = SET_ADMIN_ROLE
          break;
        case "sales":
          type = SET_SALES_ROLE
          break;
        case "investor": 
          type = SET_INVESTOR_ROLE
          break;
        case "operation manager": 
          type = SET_OPMAN_ROLE
          break;
        default:
          type = UNRECOGNIZED_ROLE
          break;
      }
      
      dispatch({
        type: type,
      })

      dispatch({
        type: GET_USER_DETAIL,
        data,
      });

    } catch (err) {
      return err
      // throw err?.response?.data;      
    }
  }
}

import { GET_CLASSES, GET_CLASS_CATEGORY, GET_INSTRUCTOR, GET_PACKAGE_CLASS } from "../actions/ClassActions";

const initialState = {
    classes: [],
    classCategory: [],
    instructor: [],
    packageClass: [],
};

const ClassReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_CLASSES: {
            return {
                ...state,
                classes: action.data,
            };
        }

        case GET_CLASS_CATEGORY: {
            return {
                ...state,
                classCategory: action.data,
            };
        }

        case GET_INSTRUCTOR: {
            return {
                ...state,
                instructor: action.data,
            };
        }

        case GET_PACKAGE_CLASS: {
            return {
                ...state,
                packageClass: action.data,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default ClassReducer;
import { Api } from '../api'

export const GET_LIST_EMPLOYEE = "GET_LIST_EMPLOYEE";
export const GET_EMPLOYEE_CHECKIN = "GET_EMPLOYEE_CHECKIN";
export const GET_SALES_BUDGET = "GET_SALES_BUDGET";

export const getListEmployee = ({ page, size, orgId = '', clubId = '', search, departmentId = '', positionId = '' }) => {
  return async (dispatch) => {
    try {
      if (search === null) search = ""
      const resp = await Api.get(`employee?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&search=${search}&departmentId=${departmentId}&positionId=${positionId}`)
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_LIST_EMPLOYEE,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

export const getEmployeeByBrandClub = (orgId = null, clubId = null) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`employee?orgId=${orgId}&clubId=${clubId}`)
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_LIST_EMPLOYEE,
        data,
      })

    } catch (err) {
      return err
      // throw err?.response?.data
    }
  }
}

export const getEmployeeCheckin = ({ page = "", size = "", orgId = "", clubId = "", employeeId = "", positionId = "", checkin = "", checkout = "", isExport = false }) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(
        `report/employee-checkin?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&employeeId=${employeeId}&positionId=${positionId}&checkin=${checkin}&checkout=${checkout}`
      )
      let data = resp.data.data ? resp.data.data : []

      if (isExport) {
        return data;
      }

      dispatch({
        type: GET_EMPLOYEE_CHECKIN,
        data,
      })

    } catch (error) {
      return error
    }
  }
}

export const getSalesBudget = ({ page = "", size = "", orgId = "", clubId = "", date = "", salesId = "" }) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(
        `sales-budget?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&date=${date}&salesId=${salesId}`
      )
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_SALES_BUDGET,
        data,
      })

    } catch (error) {
      return error
    }
  }
}
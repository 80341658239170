import { Api } from '../api'

export const GET_CLASS_SCHEDULE = "GET_CLASS_SCHEDULE";
export const GET_CLASS_SCHEDULE_DETAIL = "GET_CLASS_SCHEDULE_DETAIL";
export const GET_TRAINER_SCHEDULE = "GET_TRAINER_SCHEDULE";
export const GET_TRAINER_SCHEDULE_DETAIL = "GET_TRAINER_SCHEDULE_DETAIL";
export const GET_MEMBER_SCHEDULE = "GET_MEMBER_SCHEDULE";

export const getClassSchedule = ({ page = '', size = '', orgId = '', clubId = '', classId = '', instructorId = '', startDate = '', endDate = '' }) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(
        `schedule/class?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&classId=${classId}&instructorId=${instructorId}&startDate=${startDate}&endDate=${endDate}`
      )
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_CLASS_SCHEDULE,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

export const getClassSchedulebyOrg = (orgId = '', clubId = '') => {
  return async (dispatch) => {
    try {
      const  resp = await Api.get(`schedule/class?orgId=${orgId}&clubId=${clubId}`)
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_CLASS_SCHEDULE,
        data,
      })

    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
}

export const getTrainerSchedule = ({ page = '', size = '', orgId = '', clubId = '', startDate = '', endDate = '' }) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`schedule/trainer?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}`)
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_TRAINER_SCHEDULE,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

export const getScheduleDetail = (id) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`schedule/class/${id}`)
      let data = resp.data.data ? resp.data.data : []
      dispatch({
        type: GET_CLASS_SCHEDULE_DETAIL,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

export const getScheduleTrainerDetail = (orgId, clubId, classId, date) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`schedule/trainer/detail?orgId=${orgId}&clubId=${clubId}&classId=${classId}&date=${date}`)
      let data = resp.data.data ? resp.data.data : []
      dispatch({
        type: GET_CLASS_SCHEDULE_DETAIL,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

export const getScheduleMember = (page, size, scheduleId, isExport = false) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`schedule/booking-class?scheduleId=${scheduleId}`)
      let data = resp.data.data ? resp.data.data : []

      if (isExport) {
        return data;
      } 

      dispatch({
        type: GET_MEMBER_SCHEDULE,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};


import {
    GET_MEMBER_CHECK, GET_MEMBER_CHECK_PT, GET_MEMBER_CHECK_CLASS
} from "../actions/DashboardActions";

const initialState = {
    memberCheck: {},
    memberCheckPt: {},
    memberCheckClass: {},
};

const DashboardReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_MEMBER_CHECK: {
            return {
                ...state,
                memberCheck: action.data
            };
        }

        case GET_MEMBER_CHECK_PT: {
            return {
                ...state,
                memberCheckPt: action.data
            };
        }

        case GET_MEMBER_CHECK_CLASS: {
            return {
                ...state,
                memberCheckClass: action.data
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default DashboardReducer;

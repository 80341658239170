import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import uiKitsRoutes from "./views/ui-kits/uiKitsRoutes";
import formsRoutes from "./views/forms/formsRoutes";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import widgetsRoute from "./views/widgets/widgetsRoute";
import chartsRoute from "./views/charts/chartsRoute";
import dataTableRoute from "./views/dataTable/dataTableRoute";
import extraKitsRoutes from "./views/extra-kits/extraKitsRoutes";
import pagesRoutes from "./views/pages/pagesRoutes";
import iconsRoutes from "./views/icons/iconsRoutes";
import invoiceRoutes from "./views/app/invoice/invoiceRoutes";
import inboxRoutes from "./views/app/inbox/inboxRoutes";
import chatRoutes from "./views/app/chat/chatRoutes";
import calendarRoutes from "./views/app/calendar/calendarRoutes";
import taskManagerRoutes from "./views/app/task-manager/taskManagerRoutes";
import ecommerceRoutes from "./views/app/ecommerce/ecommerceRoutes";
import contactRoutes from "./views/app/contact/contactRoutes";
import brandRoutes from "./views/master/brand/brandRoutes";
import mdrRoutes from "./views/master/mdr/mdrRoutes";
import clubRoutes from "./views/master/club/list/clubRoutes";
import kpiRoutes from "./views/master/kpi/kpiRoutes";
import clubBudgetRoutes from "./views/master/club/budget/budgetRoutes";
import classCategoryRoutes from "./views/master/classes/category/classesCategoryRoutes";
import classRoutes from "./views/master/classes/class/classRoutes";
import levelRoutes from "./views/master/level/levelRoutes";
import packageClassRoutes from "./views/package/classes/packageClassRoutes";
import additionalRoutes from "./views/package/additional/additionalRoutes";
import personalTrainerRoutes from "./views/package/personalTrainer/personalTrainerRoutes";
import membershipRoutes from "./views/package/membership/membershipRoutes";
import classScheduleRoutes from "./views/schedule/class/classScheduleRoutes";
import bookingClassRoutes from "./views/booking/class/bookingClassRoutes";
import bookingTrainerRoutes from "./views/booking/trainer/bookingTrainerRoutes";
import memberRoutes from "./views/master/member/list/memberRoutes";
import cutiRoutes from "./views/master/member/cuti/cutiRoutes";
import leadsRoutes from "./views/master/leads/leadsRoutes";
import complementaryRoutes from "./views/master/complementary/complementaryRoutes";
import trainerScheduleRoutes from "./views/schedule/trainer/trainerScheduleRoutes";
import transactionDailyRoutes from "./views/transaction/dailyReport/dailyReportRoutes";
import transactionClubRoutes from "./views/transaction/transactionClub/transactionClubRoutes";
import transactionSalesRoutes from "./views/transaction/transactionSales/transactionSalesRoutes";
import imagesliderRoutes from "./views/master/imageSlider/class/imagesliderRoutes";
import employeeRoutes from "./views/master/employee/list/employeeRoutes";
import salesBudgetRoutes from "./views/master/employee/salesBudget/salesBudgetRoutes";
import permissionRoutes from "./views/system/permission/permissionRoutes";
import guestFormAccessRoutes from "./views/system/guestFormAccess/guestFormAccessRoutes";
import userRoutes from "./views/system/user/userRoutes";
import reportMemberRoutes from "./views/transaction/reportMember/reportMemberRoutes";
import buyMembershipRoutes from "./views/order/buyMembership/buyMembershipRoutes";
import upgradeMembershipRoutes from "./views/order/upgradeMembership/upgradeMembershipRoutes";
import upgradeTrainerRoutes from "./views/order/upgradeTrainer/upgradeTrainerRoutes";
import listOrderRoutes from "./views/order/listOrder/listOrderRoutes";
import listVoidRoutes from "./views/order/voidHistory/listVoidRoutes";
import listDpRoutes from "./views/order/payDownPayment/listDpRoutes";
import orLogRoutes from "./views/transaction/orLog/orLogRoutes";
import historyTrainerRoutes from "./views/transaction/trainer/historyTrainerRoutes";
import checkinManualRoutes from "./views/checkinManual/checkinManualRoutes";
import buyTrainerRoutes from "./views/order/buyTrainer/buyTrainerRoutes";
import reportRetainerRoutes from "./views/transaction/reportRetainer/reportRetainerRoutes";
import reportEmployeeRoutes from "./views/transaction/reportEmployee/reportEmployeeRoutes";
import salesDeptRoutes from "./views/transaction/dailyReport/salesDepartment/salesDeptRoutes";
import reportCommisionRoutes from "./views/transaction/reportCommision/reportCommisionRoutes";
import movementRoutes from "./views/master/classes/movement/movementRoutes";
import transferPackageRoutes from "./views/order/transferPackage/transferRoutes";
import extendPtRoutes from "./views/order/extendPt/extendPtRoutes";
import coinMgmRoutes from "./views/master/coinMgm/coinMgmRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard/v1" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionsRoutes,
  {
    path: "/",
    component: AuthGuard,
    routes: [
      ...dashboardRoutes,
      // Master Data Routes
      ...checkinManualRoutes,      
      ...brandRoutes, ...clubRoutes, ...kpiRoutes, ...clubBudgetRoutes, ...classCategoryRoutes, ...classRoutes, 
      ...levelRoutes, ...memberRoutes, ...cutiRoutes, ...leadsRoutes, ...mdrRoutes, ...coinMgmRoutes,
      ...imagesliderRoutes, ...employeeRoutes, ...complementaryRoutes, ...salesBudgetRoutes, ...movementRoutes,
      // Package Routes
      ...packageClassRoutes, ...additionalRoutes, ...personalTrainerRoutes, ...membershipRoutes,
      // Transaction Buy Membership Routes
      ...buyMembershipRoutes, ...buyTrainerRoutes, ...upgradeMembershipRoutes, ...upgradeTrainerRoutes,
      // List Order Transaction Routes
      ...listOrderRoutes, ...listDpRoutes, ...listVoidRoutes, ...transferPackageRoutes, ...extendPtRoutes,
      // Schedule Routes
      ...classScheduleRoutes, ...trainerScheduleRoutes,
      // Report Routes
      ...transactionDailyRoutes, ...transactionClubRoutes, ...transactionSalesRoutes, ...reportMemberRoutes,
      ...reportRetainerRoutes, ...reportEmployeeRoutes, ...orLogRoutes, ...historyTrainerRoutes,
      ...salesDeptRoutes, ...reportCommisionRoutes,
      // Booking Routes
      ...bookingClassRoutes, ...bookingTrainerRoutes, ...permissionRoutes, ...guestFormAccessRoutes, ...userRoutes,
      ...uiKitsRoutes,
      ...formsRoutes,
      ...widgetsRoute,
      ...chartsRoute,
      ...dataTableRoute,
      ...extraKitsRoutes,
      ...pagesRoutes,
      ...iconsRoutes,
      ...invoiceRoutes,
      ...inboxRoutes,
      ...chatRoutes,
      ...taskManagerRoutes,
      ...calendarRoutes,
      ...ecommerceRoutes,
      ...contactRoutes,
      ...redirectRoute,
      ...errorRoute
    ]
  }
];

export default routes;
